html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* always have those */
.wordleap-img-button {
  display: inline-block;
  background-color: white;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  border: 0px;
  outline: none !important;
}

.wordleap-img-button-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background-color: transparent;
}

.btn:focus {
  outline: none;
  box-shadow: none !important;
}

/* btn-primary */
.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #6ac979 !important;
  border-color: #6ac979 !important;
}

.btn-primary:hover {
  background-color: #61b56d !important;
  border-color: #61b56d !important;
}

/* btn-secondary */
.btn-secondary,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: white !important;
  border-color: rgb(72, 72, 72) !important;
  color: rgb(72, 72, 72) !important;
}

.btn-secondary:hover {
  border-color: rgb(33, 37, 41) !important;
  color: rgb(33, 37, 41) !important;
}

/* .btn-outline-primary, */

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:visited {
  background-color: #ffffff !important;
  border-color: #6ac979 !important;
  color: #6ac979 !important;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:visited {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-outline-primary:hover {
  background-color: transparent !important;
  color: #007bff !important;
}

.radio-button {
  border: 1px solid rgba(106, 201, 121, 0.9) !important;
  border-radius: 100px !important;
}

.button-selected {
  background-color: rgba(106, 201, 121, 0.9) !important;
  color: white !important;
}

.btn-grey-link {
  color: #6e6e6e !important;
}

.h1 {
  font-size: 2.3rem !important;
}

.non-select-text {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.triangle:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid rgb(51, 167, 71);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 50px;
  left: 25px;
}

.triangle:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  top: 51px;
  left: 27.5px;
}
